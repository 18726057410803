import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userInfoSlice";

import "./header.css";

function Header() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [clicked1, setClicked1] = useState(false);
  const menuRef = useRef(null);
  const dispatch = useDispatch();

  const handleClick = () => {
    setClicked1(!clicked1);
  };

  const handleLinkClick = () => {
    setClicked1(false);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setClicked1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setClicked1]);

  return (
    <div className="sticky-header">
      <style>
        {`
          body {
            overflow-y: ${clicked1 ? "hidden" : "auto"};
          }
          
          .sticky-header {
            position: sticky;
            top: 0;
            z-index: 1000;
            background-color: white; /* Add your preferred background color */
          }
        `}
      </style>
      <div className="nav-bar">
        <a>
          <img src="/assets/images/dashboard/petssmart-logo.png" />
        </a>
        <div>
          <ul
            ref={menuRef}
            id="navbar"
            className={clicked1 ? " #navbar active" : "#navbar"}
          >
            <li className="nav-links">
              <Link to={"/"} onClick={handleLinkClick} className="active">
                Home
              </Link>
            </li>
            <li className="nav-links">
              <Link to={"/plans"} onClick={handleLinkClick}>
                Plans
              </Link>
            </li>
            <li className="nav-links">
              <Link to={"/payments"} onClick={handleLinkClick}>
                Payments
              </Link>
            </li>
            <li className="nav-links">
              <Link to={"/sos"} onClick={handleLinkClick}>
                SOS Report
              </Link>
            </li>
            <li className="nav-links">
              <Link to={"/account"} onClick={handleLinkClick}>
                Account
              </Link>
            </li>
            <li className="nav-links">
              <Link to={"/reminder"} onClick={handleLinkClick}>
                Reminder
              </Link>
            </li>
            <li className="nav-links">
              {isAuthenticated ? (
                <Link onClick={handleLogout}>LogOut</Link>
              ) : (
                <Link to={"/login"} onClick={handleLinkClick}>
                  Login
                </Link>
              )}
            </li>
            <li className="nav-links">
              <Link to={"/settings"} onClick={handleLinkClick}>
                <i className="fa-solid fa-gear"></i>
              </Link>
            </li>
            <li className="nav-links">
              <a className="text-white" to={"/"} onClick={handleShow}>
                <i className="fa-solid fa-bell"></i>
              </a>
            </li>
          </ul>
        </div>
        <div id="mobile" onClick={handleClick}>
          <i className={clicked1 ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
      <div className="container">
        <Modal
          className="notification-modal"
          show={show}
          onHide={handleClose}
          style={{ position: "re", right: "10px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h1
                style={{ fontWeight: "600", fontSize: "3rem" }}
                className="mt-2"
              >
                Notifications
              </h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="notification mt-2">
              {/* <h2 style={{ fontWeight: "600" }}>{notificationData?.title}</h2>
              <h3>{notificationData?.body}</h3> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Header;
