import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTopOnRouteChange from "./ScrollToTopOnRouteChange";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
  const location = useLocation();
  const noHeaderFooterPaths = ["/login", "/otpverification", "/register"];
  const showHeaderFooter = !noHeaderFooterPaths.includes(
    location.pathname.toLowerCase()
  );

  return (
    <div>
      {showHeaderFooter && <Header />}
      <ScrollToTopOnRouteChange />
      <main>{children}</main>
      {/* {showHeaderFooter && <Footer />} */}
    </div>
  );
}

export default Layout;
