import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authUserSlice from "./userInfoSlice";
import notificationSlice from "./notificationSlice";

const reducers = combineReducers({
  auth: authUserSlice,
  notification: notificationSlice,
});

const store = configureStore({ reducer: reducers });
export { store };
