import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";

import "../Login/login.css";
import { postApi } from "../../services/axiosInterceptors";
import { login } from "../../redux/userInfoSlice";

function OTPVerification() {
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state;

  console.log(otp);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      user_id: localStorage?.getItem("tempUserId"),
      otp,
    };

    if (data.otp.length < 4) {
      alert("Fill 4 digit otp");
      return;
    }
    await postApi("verify_otp", data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("tempUserId");

          let obj = {
            device_available: state.data.device_available,
            user_device: state.data.user_device,
            user_id: state.data.user_id,
          };

          if (state.route === "login") {
            console.log(obj, "check berfore");

            localStorage.setItem("userInfo", JSON.stringify(obj));
            dispatch(login(obj));
            navigate("/successful");
          } else {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        alert("Wrong OTP!");
      });
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    let phone = localStorage?.getItem("tempPhone");

    await postApi("resend_otp", { phone: phone.slice(3, phone.length) })
      .then((res) => {
        if (res.status === 200) {
          alert("Otp sent!");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let tempPhone = localStorage?.getItem("tempPhone");
    setPhone(tempPhone);
  }, []);

  return (
    <div className="container-fluid login-background">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-3">
            <h1 className="text-white mb-4 font-bold">OTP Verification</h1>
            <h3 className="text-white mb-4">
              Enter the verification code we just sent on your number {phone}.
            </h3>

            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{
                    width: "20px",
                    textAlign: "center",
                    height: "50px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    flex: "1",
                    margin: "0 5px",
                  }}
                />
              )}
            />
            <h3 className="text-white mt-4 mb-4">
              Didn’t received code?{" "}
              <span style={{ color: "#F5960E" }} onClick={handleResendOtp}>
                Resend
              </span>
            </h3>

            <div
              className="p-3 mb-4  mt-4"
              style={{
                textAlign: "center",
                backgroundColor: "#F5960E",
                borderRadius: 10,
              }}
              onClick={handleSubmit}
            >
              <button
                className="text-white"
                style={{
                  border: "none",
                  fontSize: 20,
                  backgroundColor: "#F5960E",
                  fontWeight: 600,
                }}
              >
                Verify
              </button>
            </div>

            {/* <h3 className='text-white'>Don’t have an account? <Link to={'/register'} style={{ fontWeight: '600', color: "#f5960e" }}>Register Now</Link></h3> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
