import React from 'react'
import Dashboard from '../Dashboard/Dashboard'
import Plans from '../Plans/Plans'

function Home() {
    return (
        <div>
            <Dashboard />
            {/* <Plans /> */}

        </div>
    )
}

export default Home