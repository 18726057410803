import React, { useState } from "react";
import "../Login/login.css";
import { Link } from "react-router-dom";
import ScanImg from "../../dashboard/link device.png";
import "../Successful/successful.css";

function LinkDevice() {
  const [otp, setOtp] = useState("");

  return (
    <div className="container-fluid successful-background">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-3 text-center">
            <img src={ScanImg} alt="" />
            <h1
              className="text-white mb-4 mt-4 font-bold"
              style={{ fontSize: "3rem", fontWeight: "600" }}
            >
              Scan To Connect Your New Device
            </h1>
            <h3 className="text-white mb-4 font-bold">
              Your device will connected after scanning QR code.
            </h3>
            <Link to={"/qrscanner"}>
              <div
                className="p-3 mb-4  mt-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "#F5960E",
                  borderRadius: 10,
                }}
              >
                <button
                  className="text-white"
                  style={{
                    border: "none",
                    fontSize: 20,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                  }}
                >
                  Link Device
                </button>
              </div>
            </Link>
            <Link to={"/"}>
              <div
                className="p-3 mb-4  mt-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "#F5960E",
                  borderRadius: 10,
                }}
              >
                <button
                  className="text-white"
                  style={{
                    border: "none",
                    fontSize: 20,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                  }}
                >
                  Skip
                </button>
              </div>
            </Link>
            {/* <h3 className='text-white'>Don’t have an account? <Link to={'/register'} style={{ fontWeight: '600', color: "#f5960e" }}>Register Now</Link></h3> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkDevice;
