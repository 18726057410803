import { createSlice } from "@reduxjs/toolkit";
import { authState } from "./states/userInfoState";

export const authUserSlice = createSlice({
  name: "authUser",
  initialState: authState,
  reducers: {
    login: (state, action) => {
      console.log(action, "action");
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = null;
      state.user = {};
      localStorage.removeItem("userInfo");
    },
  },
});

export const { login, logout } = authUserSlice.actions;
export default authUserSlice.reducer;
