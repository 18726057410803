import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDdna5O73UK4egXIM38xrkH5PUGD9mS83M",
  authDomain: "petssmart.firebaseapp.com",
  projectId: "petssmart",
  storageBucket: "petssmart.appspot.com",
  messagingSenderId: "434104247634",
  appId: "1:434104247634:web:c77bab7f3a47891cf2c68b",
  measurementId: "G-CE9C1HHC4L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
