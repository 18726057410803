import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";

import "./reminder.css";
import "react-calendar/dist/Calendar.css";

import Loading from "../Loading/Loading";
import { postApi } from "../../services/axiosInterceptors";

function Reminder() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [reminder, setReminder] = useState({
    mac_address: user?.user_device?.mac_address,
    reminder_date: "",
    reminder_title: "",
    reminder_description: "",
  });

  const customCalendarStyles = {
    fontSize: "1.7rem",
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setReminder((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await postApi("add_reminder", reminder)
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return <Loading />;
  }

  console.log(reminder);
  return (
    <div className="container">
      <div className="row mt-4 mb-4 gap-4">
        <form onSubmit={handleSubmit}>
          <div className="col-xl-4 col-md-5 col-sm-7 mt-4">
            <Calendar
              className={""}
              onChange={(date) =>
                setReminder((prev) => ({
                  ...prev,
                  reminder_date: new Date(date).toISOString().split("T")[0],
                }))
              }
              value={reminder.reminder_date}
              style={customCalendarStyles}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-sm-5">
            <h3 className=" mb-4 mt-4">Title</h3>
            <input
              className="pet-info"
              type="text"
              name="reminder_title"
              value={reminder?.reminder_title}
              onChange={handleChange}
            />
            {/* <h3 className="mt-4 mb-4">Time</h3>
          <input className="pet-info" type="time" /> */}
            <h3 className="mt-4 mb-4">Pet Name</h3>
            <textarea
              className="pet-info"
              type="text"
              name="reminder_description"
              value={reminder?.reminder_description}
              onChange={handleChange}
            />
            <div
              className="p-3 mb-4 mt-4"
              style={{
                textAlign: "center",
                backgroundColor: "#F5960E",
                borderRadius: 8,
              }}
            >
              <button
                className="text-white"
                style={{
                  border: "none",
                  fontSize: 20,
                  backgroundColor: "#F5960E",
                  fontWeight: 600,
                }}
                type="submit"
              >
                Save Reminder
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Reminder;
