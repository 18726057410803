import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Scanner } from "@yudiel/react-qr-scanner";

import "../Login/login.css";

function QrScanner() {
  const [QR, setQR] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (QR) {
      console.log(QR);
      localStorage.setItem("QR", JSON.stringify(QR));
      navigate("/deviceconnect");
    }
  }, [QR]);

  return (
    <div className="container-fluid login-background">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-3">
            <h1 className="text-white mb-4 font-bold">
              Scan To Connect Your New Device
            </h1>
            <Scanner
              onResult={(text, result) => setQR(JSON.parse(text))}
              onError={(error) => console.log(error?.message)}
              style={{
                display: "inline-block", // Make the scanner input inline-block
                verticalAlign: "middle", // Align the scanner input vertically in the middle
                width: "100%", // Set the width to fill the container
                height: "50px", // Set the height of the scanner input
                fontSize: "16px", // Set the font size
                padding: "10px", // Add padding for better appearance
                boxSizing: "border-box", // Include padding and border in the element's total width and height
                border: "1px solid #ccc", // Add border for visual distinction
                borderRadius: "5px", // Add border radius for rounded corners
                transition: "all 0.3s ease", // Add transition for smooth animation
              }}
            />

            {/* <Link to={"/deviceconnect"}> */}
            <div
              className="p-3 mb-4  mt-4"
              style={{
                textAlign: "center",
                backgroundColor: "#F5960E",
                borderRadius: 10,
              }}
            >
              <button
                className="text-white"
                style={{
                  border: "none",
                  fontSize: 20,
                  backgroundColor: "#F5960E",
                  fontWeight: 600,
                }}
              >
                Verify
              </button>
            </div>
            {/* </Link> */}
            {/* <h3 className='text-white'>Don’t have an account? <Link to={'/register'} style={{ fontWeight: '600', color: "#f5960e" }}>Register Now</Link></h3> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrScanner;
