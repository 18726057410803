import React from 'react';
import '../Plans/plans.css';
import './settings.css'


function Settings() {
    return (
        <div className='content-body2 mt-4 p-4'>
            <div className='container mb-4'>
                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Settings</h1>
                <div className='row'>

                    <div className="col-xl-4 col-sm-6 mb-4">
                        <div className="card">
                            <div className="card-body2">
                                <h1 style={{ fontWeight: "600", fontSize: "2.5rem" }}>Lock</h1>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className='d-flex align-items-center justify-content-between'>

                                        <div className='mt-3'>
                                            <h2 style={{ fontSize: "1.8rem", fontWeight: "600", color: "#f5960e" }}>Security</h2>
                                        </div>
                                    </div>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </div>
                                <div className='row mx-1'><hr /></div>
                                <div className="col-xl-12 col-sm-6 mb-4">
                                    <h1 style={{ fontWeight: "600", fontSize: "" }}>Language</h1>
                                    <div className="d-flex align-items-center justify-content-between mt-4">
                                        <h2 style={{ fontSize: "1.8rem" }}>Suggested</h2>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between mt-4'>
                                        <h2 style={{ fontSize: "1.8rem" }}>English (US)</h2>
                                        <input type='radio' name='language' />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between mt-4'>
                                        <h2 style={{ fontSize: "1.8rem" }}>English (UK)</h2>
                                        <input type='radio' name='language' className='p-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-12 mb-4">
                        <div className="card">
                            <div className="card-body2">
                                <h1 style={{ fontWeight: "600", fontSize: "2.5rem" }}>Notifications</h1>
                                <div className="row">
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <h1 style={{ fontWeight: "600", fontSize: "" }}>Common</h1>
                                        <div className="d-flex align-items-center justify-content-between mt-4">
                                            <h2 style={{ fontSize: "1.8rem" }}>General Notification</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 style={{ fontSize: "1.8rem" }}>Sound</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 style={{ fontSize: "1.8rem" }}>Vibrate</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row mx-1'><hr /></div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <h1 style={{ fontWeight: "600", fontSize: "" }}>System & services update</h1>
                                        <div className="d-flex align-items-center justify-content-between mt-4">
                                            <h2 style={{ fontSize: "1.8rem" }}>App updates</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 style={{ fontSize: "1.8rem" }}>Bill Reminder</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 style={{ fontSize: "1.8rem" }}>Recharge</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 style={{ fontSize: "1.8rem" }}>Payment Request</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row mx-1'><hr /></div>
                                    <div className="col-xl-12 col-sm-6 mb-4">
                                        <h1 style={{ fontWeight: "600", fontSize: "" }}>Others</h1>
                                        <div className="d-flex align-items-center justify-content-between mt-4">
                                            <h2 style={{ fontSize: "1.8rem" }}>New Service Available</h2>
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-sm-6 mb-4">
                        <div className="card">
                            <div className="card-body2">
                                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Password</h1>
                                <div className=''>
                                    <div className='col-xl-12 col-sm-6 col-md-6'>
                                        <h3 className='mt-4 mb-4'>Old Password</h3>
                                        <input className='pet-info' type='text' />
                                    </div>
                                    <div className='col-xl-12 col-sm-6 col-md-6'>
                                        <h3 className='mt-4 mb-4'>New Password</h3>
                                        <input className='pet-info' type='text' />
                                    </div>
                                    <div className='col-xl-12 col-sm-6 col-md-6'>
                                        <h3 className='mt-4 mb-4'>Old Password</h3>
                                        <input className='pet-info' type='text' />
                                    </div>


                                </div>
                                <div
                                    className="p-3 mb-4 mt-4 w-25"
                                    style={{
                                        textAlign: "center",
                                        backgroundColor: "#F5960E",
                                        borderRadius: 10,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <button
                                        className="text-white"
                                        style={{
                                            border: "none",
                                            fontSize: 20,
                                            backgroundColor: "#F5960E",
                                            fontWeight: 600
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </div>

    );
}

export default Settings;
