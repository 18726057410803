import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { getToken } from "firebase/messaging";
import { messaging } from "../../firebase.config";

import "./login.css";
import { postApi } from "../../services/axiosInterceptors";

function Login() {
  const [register, setRegister] = useState("");
  const [fcmToken, setFcm] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    await postApi("user_login", {
      phone: register.slice(3, register.length),
      fcm_token: fcmToken,
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("tempUserId", res.data.user_id);
          localStorage.setItem("tempPhone", register);

          console.log({
            data: res.data,
            route: "login",
          });

          navigate("/otpverification", {
            state: {
              data: res.data,
              route: "login",
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  async function requestPermission() {
    // Requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BFzafrR0ZNn_NolPIXcOHxDytG4gQmlar6crjRa4Ehsf2mW4DnmfSB2wfml4xktbxFn7LeCbaooKIoElUCcalcs",
        });
        setFcm(token);
        // Send the token to your server for further use.
      } catch (error) {
        console.error("Error getting token:", error);
      }
    } else if (permission === "denied") {
      // notifications are blocked
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <div className="container-fluid login-background">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-3">
            <h1 className="text-white mb-4">
              Welcome back! Glad to see you, Again!
            </h1>
            <form onSubmit={handleSubmit}>
              {/* <input
                className="pet-info"
                type="text"
                placeholder="Number"
                name="phone"
                onChange={(e) => setRegister(e.target.value)}
              /> */}
              <PhoneInput
                className="pet-info"
                placeholder="Enter phone number"
                value={register}
                onChange={setRegister}
              />
              <div
                className="p-3 mb-4  mt-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "#F5960E",
                  borderRadius: 10,
                }}
              >
                <button
                  type={"submit"}
                  className="text-white"
                  style={{
                    border: "none",
                    fontSize: 20,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                  }}
                >
                  Login
                </button>
              </div>
            </form>
            {/* <h3 className='text-white'>Don’t have an account? <Link to={'/register'} style={{ fontWeight: '600', color: "#f5960e" }}>Register Now</Link></h3> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
