// new cahgnge
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX } from "./services/config";
mapboxgl.accessToken = MAPBOX;

export default function Map({ targetLocation }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(9);
  // const targetLocation = [72.85, 19.246];
  const [position, setPosition] = useState({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    (() => {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition((prev) => ({
          ...prev,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }));

        // Update the viewport center with the user's current position
        // setViewport((prev) => ({
        //   ...prev,
        //   center: [position.coords.longitude, position.coords.latitude],
        // }));
      });
    })();
  }, []);

  let geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    // When active the map will receive updates to the device's location as it changes.
    trackUserLocation: true,
    // Draw an arrow next to the location dot to indicate which direction the device is heading.
    showUserHeading: true,
  });

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [position.longitude, position.latitude],
      zoom: zoom,
    });

    map.current.on("move", () => {
      //   setLng();
      //   setLat();

      setPosition((prev) => ({
        ...prev,
        longitude: map.current.getCenter().lng.toFixed(4),
        latitude: map.current.getCenter().lat.toFixed(4),
      }));
      setZoom(map.current.getZoom().toFixed(2));
    });

    // new mapboxgl.Marker({ color: "red" })
    //   .setLngLat([position.longitude, position.latitude])
    //   .addTo(map.current);

    new mapboxgl.Marker({ color: "blue" })
      .setLngLat(targetLocation)
      .addTo(map.current);

    map.current.addControl(geolocate);

    map.current.on("load", function () {
      geolocate.trigger();
    });
  }, [targetLocation]);

  return (
    targetLocation && (
      <div>
        <div className="sidebar">
          {/* Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} */}
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>
    )
  );
}
