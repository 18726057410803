import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./account.css";
import "../Plans/plans.css";
import device1 from "../../dashboard/device1.png";
import device2 from "../../dashboard/device2.png";

import Loading from "../Loading/Loading";
import { postApi } from "../../services/axiosInterceptors";
import { logout } from "../../redux/userInfoSlice";

function Account() {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [devices, setDevices] = useState([]);
  const [currentPet, setCurrentPet] = useState(0);
  const [state, setState] = useState({
    userInfo: {},
  });

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleGeoFenceSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let obj = {
      mac_address: devices[currentPet]?.mac_address,
      geo_fence: count,
      user_id: user?.user_id,
      lat: devices[currentPet]?.home_lat || 0,
      lng: devices[currentPet]?.home_long || 0,
    };

    await postApi("update_geofancing_area", obj).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        alert(res.data.message);
        setCount(0);
      }
    });
  };

  useEffect(() => {
    let obj = {
      user_id: user?.user_id,
    };

    const fetchData = async () => {
      setLoading(true);

      await postApi("user_details", obj)
        .then((res) => {
          if (res.status === 200) {
            setState((prev) => ({
              ...prev,
              userInfo: res.data.user,
            }));
          }
        })
        .catch((err) => console.log(err));

      await postApi("no_of_devices", obj)
        .then((res) => {
          if (res.status === 200) {
            setDevices(res.data.devices);
          }
        })
        .catch((err) => console.log(err));
    };

    obj && fetchData().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="content-body mt-4 p-4">
      <div className="container dashborad-cnt mb-4">
        <div className="row">
          {/* Map over the devices array */}
          {devices.map((device, index) => (
            <div
              key={index}
              className="col-xl-6 col-md-12 mb-4"
              onClick={() => setCurrentPet(index)}
            >
              <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>
                {device.name}
              </h1>
              <div className="card">
                <div className="card-body2">
                  <div className="row">
                    <div className="col-xl-4 col-sm-6 mb-4 w-25">
                      <div className="">
                        <div className="">
                          <img
                            src={device.device_image || device1}
                            alt=""
                            className=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 mb-2">
                      <div className=" align-items-center justify-content-between mx-2">
                        <div className="d-flex">
                          <i className="fa-solid fa-location-dot"></i>
                          <span className="mx-3">
                            <h3>Current Location</h3>
                            <h2>
                              {device.home_lat}, {device.home_long}
                            </h2>
                          </span>
                        </div>
                        <div
                          className=" mb-4"
                          style={{
                            textAlign: "center",
                            backgroundColor: "#F5960E",
                            borderRadius: 8,
                          }}
                        >
                          <button
                            className="text-white p-2"
                            style={{
                              border: "none",
                              fontSize: 15,
                              backgroundColor: "#F5960E",
                              fontWeight: 600,
                            }}
                          >
                            Relink Device
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 mb-2">
                      <div className=" align-items-center justify-content-between mx-2">
                        <div className="d-flex">
                          <i className="fa-solid fa-battery-full"></i>
                          <span className="mx-2">
                            <h3>Battery Percentage</h3>
                            <h2>90%</h2>
                          </span>
                        </div>
                        <div
                          className="mb-4"
                          style={{
                            textAlign: "center",
                            backgroundColor: "#F5960E",
                            borderRadius: 8,
                          }}
                        >
                          <button
                            className="text-white p-2"
                            style={{
                              border: "none",
                              fontSize: 15,
                              backgroundColor: "#F5960E",
                              fontWeight: 600,
                            }}
                          >
                            Disconnect Device
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 mb-2"></div>
                  </div>
                  <div className="row">
                    <div className=" col-xl-3 col-md-4 col-sm-4 mb-2 mx-2">
                      <div
                        className="mb-4"
                        style={{
                          textAlign: "center",
                          backgroundColor: "#F5960E",
                          borderRadius: 8,
                        }}
                      >
                        <button
                          className="text-white p-2"
                          style={{
                            border: "none",
                            fontSize: 15,
                            backgroundColor: "#F5960E",
                            fontWeight: 600,
                          }}
                        >
                          <i className="fa-solid fa-plus mx-1"></i>
                          Add New Device
                        </button>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 mb-2 mx-2">
                      <div
                        className="mb-4"
                        style={{
                          textAlign: "center",
                          backgroundColor: "#F5960E",
                          borderRadius: 8,
                        }}
                      >
                        <button
                          className="text-white p-2"
                          style={{
                            border: "none",
                            fontSize: 15,
                            backgroundColor: "#F5960E",
                            fontWeight: 600,
                          }}
                        >
                          <i className="fa-solid fa-plus mx-1"></i>
                          Add User
                        </button>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 mb-2 mx-2">
                      <div
                        className="mb-4"
                        style={{
                          textAlign: "center",
                          backgroundColor: "#F5960E",
                          borderRadius: 8,
                        }}
                      >
                        <button
                          className="text-white p-2"
                          style={{
                            border: "none",
                            fontSize: 15,
                            backgroundColor: "#F5960E",
                            fontWeight: 600,
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                          Update
                        </button>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 mb-2 mx-2">
                      <div
                        className="mb-4"
                        style={{
                          textAlign: "center",
                          backgroundColor: "#F5960E",
                          borderRadius: 8,
                        }}
                      >
                        <button
                          className="text-white p-2"
                          style={{
                            border: "none",
                            fontSize: 15,
                            backgroundColor: "#F5960E",
                            fontWeight: 600,
                          }}
                        >
                          Send SOS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row d-flex mt-2">
          <div className="col-xl-6 col-sm-6 mb-4">
            <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Geofencing</h1>
            <div className="card">
              <div className="card-body2">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <i
                    className="fa-solid fa-arrow-left"
                    onClick={() => setCount((count) => count - 1)}
                  ></i>
                  <div
                    className="p-2 border px-4 w-50 text-center"
                    style={{ fontSize: "2rem" }}
                  >
                    {count} meter
                  </div>
                  <i
                    className="fa-solid fa-arrow-right"
                    onClick={() => setCount((count) => count + 1)}
                  ></i>
                </div>
                <div
                  className="p-3 mb-4"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#F5960E",
                    borderRadius: 10,
                  }}
                  onClick={handleGeoFenceSubmit}
                >
                  <button
                    className="text-white"
                    style={{
                      border: "none",
                      fontSize: 20,
                      backgroundColor: "#F5960E",
                      fontWeight: 600,
                    }}
                  >
                    Add New Geofencing Location{" "}
                    <span>
                      <i className="fa-solid fa-location-dot"></i>
                    </span>
                  </button>
                </div>
                <h3>( It Will Add Your Device Current Location )</h3>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-sm-6 mb-4">
            <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>My Info</h1>
            <div className="card">
              <div className="card-body2">
                <div className="row">
                  <div className="col my-info">
                    <h2>Name</h2>
                    <h2>Gender</h2>
                    <h2>Number</h2>
                    <h2>Email</h2>
                  </div>
                  <div className="col text-end my-info-second-col">
                    <h2>{state?.userInfo?.full_name}</h2>
                    <h2>Male</h2>
                    <h2>{state?.userInfo?.phone}</h2>
                    <h2>{state?.userInfo?.email}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex mt-2">
          <div className="col-xl-6 col-sm-6 mb-4">
            <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Pet </h1>
            <div className="card">
              <div
                className="card-body3"
                style={{
                  backgroundImage: `url(https://api.petssmart.co.in/pet_images/${devices[currentPet]?.pet_image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right",
                }}
              ></div>
            </div>
          </div>
          <div className="col-xl-6 col-sm-6 mb-4">
            <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Pets Info</h1>
            <div className="card">
              <div className="card-body2">
                <div className="row">
                  <div className="col my-info">
                    <h2>Name</h2>
                    <h2>Breed</h2>
                    <h2>Weight</h2>
                    <h2>Age</h2>
                  </div>
                  <div className="col text-end my-info-second-col">
                    <h2>{devices[currentPet]?.pet_name}</h2>
                    <h2>{devices[currentPet]?.breed}</h2>
                    <h2>{devices[currentPet]?.weight}</h2>
                    <h2>{devices[currentPet]?.age}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex mt-3">
          <div className="col-xl-6 col-sm-6 mb-4">
            <div className="d-flex gap-4">
              <Link to={"/editinfo"} className="text-decoration-none">
                <button
                  className="d-flex align-items-center border p-3"
                  style={{
                    border: "none",
                    fontSize: 20,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  <h3
                    className="mt-2 mx-3 new-card text-white"
                    style={{
                      border: "none",
                      fontSize: 15,
                      backgroundColor: "#F5960E",
                      fontWeight: 600,
                    }}
                  >
                    Edit Info
                  </h3>
                </button>
              </Link>
              <button
                className="d-flex align-items-center border p-3"
                style={{
                  border: "none",
                  fontSize: 20,
                  backgroundColor: "#F5960E",
                  fontWeight: 600,
                  color: "white",
                }}
                onClick={handleLogout}
              >
                <h3
                  className="mt-2 mx-3 new-card text-white"
                  style={{
                    border: "none",
                    fontSize: 15,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                  }}
                >
                  Logout
                </h3>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
