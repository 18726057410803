import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import { useNavigate } from "react-router-dom";

import "./dashboard.css";
import Loading from "../Loading/Loading";
import { postApi } from "../../services/axiosInterceptors";
import { MAPBOX } from "../../services/config";
import Map from "../../Map";

mapboxgl.accessToken = MAPBOX;

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [currentPet, setCurrentPet] = useState(1);

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [activity, setActivity] = useState({
    daily: {},
    sleep: {},
    location: {},
    device: {},
  });

  const navigate = useNavigate();

  useEffect(() => {
    let obj = {
      user_id: user?.user_id,
      mac_address: user?.user_device?.mac_address,
    };

    const fetchData = async () => {
      setLoading(true);
      await postApi("daily_activities", obj)
        .then((res) => {
          if (res.status === 200) {
            setActivity((prev) => ({
              ...prev,
              daily: res.data,
            }));
          }
        })
        .catch((err) => console.log(err));

      await postApi("sleep_data", obj)
        .then((res) => {
          if (res.status === 200) {
            setActivity((prev) => ({
              ...prev,
              sleep: res.data,
            }));
          }
        })
        .catch((err) => console.log(err));

      await postApi("get_current_location", { mac_address: obj.mac_address })
        .then((res) => {
          if (res.status === 200) {
            setActivity((prev) => ({
              ...prev,
              location: res.data,
            }));
          }
        })
        .catch((err) => console.log(err));

      await postApi("no_of_devices", { user_id: obj.user_id })
        .then((res) => {
          if (res.status === 200) {
            setActivity((prev) => ({
              ...prev,
              device: res.data,
            }));
          }
        })
        .catch((err) => console.log(err));
    };

    obj && fetchData().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="content-body mt-4 p-4 mb-4 dashborad-cnt">
        <div className="container-fluid">
          <div className="row">
            {activity?.device?.devices?.map((pet, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-6 mb-4"
                  key={index}
                  onClick={() => setCurrentPet(index)}
                >
                  <div
                    className={`${
                      pet.pet_type === "Dog" ? "card dogs" : "card cats"
                    }`}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center  justify-content-between">
                        <h1 className="mt-4">
                          {pet.pet_type === "Dog" ? "Bark" : "Meow"}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className={`col-xl-3 col-sm-6 mb-4`}>
              <div
                className={`card py-2 ${
                  activity?.daily?.daily_activities?.wifi === "OFF"
                    ? "activeBody"
                    : "inactiveBody"
                }`}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="icon-box icon-box-lg bg-purple-light rounded-circle">
                      <i
                        className="fa-solid fa-globe"
                        style={{ color: "#F8B93F" }}
                      ></i>
                    </div>
                    <div className={`total-projects ms-3`}>
                      <h1
                        className={`count  ${
                          activity?.daily?.daily_activities?.wifi === "OFF"
                            ? "activeFont"
                            : ""
                        }`}
                      >
                        Internet
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-xl-3 col-sm-6 mb-4`}>
              <div
                className={`card py-2 ${
                  activity?.daily?.daily_activities?.wifi === "ON"
                    ? "activeBody"
                    : "inactiveBody"
                }`}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="icon-box icon-box-lg bg-danger-light rounded-circle">
                      <i
                        className="fa-solid fa-wifi"
                        style={{ color: "#F8B93F" }}
                      />
                    </div>
                    <div className={`total-projects ms-3`}>
                      <h1
                        className={`count  ${
                          activity?.daily?.daily_activities?.wifi === "ON"
                            ? "activeFont"
                            : "inactiveBody"
                        }`}
                      >
                        Wi-Fi
                      </h1>
                      <span />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-5 mb-4">
              <div className={`card`}>
                <div className="card-header border-0 p-4">
                  <h2 className="heading py-2">
                    {activity?.daily?.daily_activities?.current_date} <br />{" "}
                    <span style={{ color: "#F8B93F" }}>
                      Your Daily Activity
                    </span>
                  </h2>
                </div>
                <div className="card-body py-0">
                  <div className="d-flex justify-content-center mb-4">
                    <svg
                      className="pet-range"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 158 158"
                      style={{ enableBackground: "new 0 0 158 158" }}
                      xmlSpace="preserve"
                    >
                      <style
                        type="text/css"
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n\t\t\t\t\t\t\t\t\t\t\t.st0 {\n\t\t\t\t\t\t\t\t\t\t\t\tfill: url(#SVGID_1_);\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.st1 {\n\t\t\t\t\t\t\t\t\t\t\t\tfill: none;\n\t\t\t\t\t\t\t\t\t\t\t\tstroke: #FFFFFF;\n\t\t\t\t\t\t\t\t\t\t\t\tstroke-opacity: 0.1;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.pet-range {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 50%;\n\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t",
                        }}
                      />
                      <radialGradient
                        id="SVGID_1_"
                        cx="-454.1772"
                        cy="625.8228"
                        r={1}
                        gradientTransform="matrix(4.837355e-15 79 79 -4.837355e-15 -49361 35959)"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset={0} style={{ stopColor: "#F5960E" }} />
                        <stop
                          offset={1}
                          style={{ stopColor: "#F5960E", stopOpacity: 0 }}
                        />
                      </radialGradient>
                      <path
                        className="st0"
                        d="M79,0L79,0c43.6,0,79,35.4,79,79l0,0c0,43.6-35.4,79-79,79l0,0c-43.6,0-79-35.4-79-79l0,0C0,35.4,35.4,0,79,0z"
                      />
                      <path
                        className="st1"
                        d="M79,6.5L79,6.5c40,0,72.5,32.5,72.5,72.5l0,0c0,40-32.5,72.5-72.5,72.5l0,0C39,151.5,6.5,119,6.5,79l0,0
								   C6.5,39,39,6.5,79,6.5z"
                      />
                      <path
                        className="st1"
                        d="M79,0.5L79,0.5c43.4,0,78.5,35.1,78.5,78.5l0,0c0,43.4-35.1,78.5-78.5,78.5l0,0c-43.4,0-78.5-35.1-78.5-78.5
								   l0,0C0.5,35.6,35.6,0.5,79,0.5z"
                      />
                      <path
                        className="st1"
                        d="M79,14.5L79,14.5c35.6,0,64.5,28.9,64.5,64.5l0,0c0,35.6-28.9,64.5-64.5,64.5l0,0c-35.6,0-64.5-28.9-64.5-64.5
								   l0,0C14.5,43.4,43.4,14.5,79,14.5z"
                      />
                      <path
                        className="st1"
                        d="M79,22.5L79,22.5c31.2,0,56.5,25.3,56.5,56.5l0,0c0,31.2-25.3,56.5-56.5,56.5l0,0c-31.2,0-56.5-25.3-56.5-56.5
								   l0,0C22.5,47.8,47.8,22.5,79,22.5z"
                      />
                      <path
                        className="st1"
                        d="M79,30.5L79,30.5c26.8,0,48.5,21.7,48.5,48.5l0,0c0,26.8-21.7,48.5-48.5,48.5l0,0c-26.8,0-48.5-21.7-48.5-48.5
								   l0,0C30.5,52.2,52.2,30.5,79,30.5z"
                      />
                      <path
                        className="st1"
                        d="M79,38.5L79,38.5c22.4,0,40.5,18.1,40.5,40.5l0,0c0,22.4-18.1,40.5-40.5,40.5l0,0c-22.4,0-40.5-18.1-40.5-40.5
								   l0,0C38.5,56.6,56.6,38.5,79,38.5z"
                      />
                      <path
                        className="st1"
                        d="M79,46.5L79,46.5c17.9,0,32.5,14.6,32.5,32.5l0,0c0,17.9-14.6,32.5-32.5,32.5l0,0c-17.9,0-32.5-14.6-32.5-32.5
								   l0,0C46.5,61.1,61.1,46.5,79,46.5z"
                      />
                      <path
                        className="st1"
                        d="M79,54.5L79,54.5c13.5,0,24.5,11,24.5,24.5l0,0c0,13.5-11,24.5-24.5,24.5l0,0c-13.5,0-24.5-11-24.5-24.5l0,0
								   C54.5,65.5,65.5,54.5,79,54.5z"
                      />
                      <path
                        className="st1"
                        d="M79,62.5L79,62.5c9.1,0,16.5,7.4,16.5,16.5l0,0c0,9.1-7.4,16.5-16.5,16.5l0,0c-9.1,0-16.5-7.4-16.5-16.5l0,0
								   C62.5,69.9,69.9,62.5,79,62.5z"
                      />
                      <path
                        className="st1"
                        d="M79,68.5L79,68.5c5.8,0,10.5,4.7,10.5,10.5l0,0c0,5.8-4.7,10.5-10.5,10.5l0,0c-5.8,0-10.5-4.7-10.5-10.5l0,0
								   C68.5,73.2,73.2,68.5,79,68.5z"
                      />
                      <path
                        className="st1"
                        d="M79,73.5L79,73.5c3,0,5.5,2.5,5.5,5.5l0,0c0,3-2.5,5.5-5.5,5.5l0,0c-3,0-5.5-2.5-5.5-5.5l0,0
								   C73.5,76,76,73.5,79,73.5z"
                      />
                      <path
                        className="st1"
                        d="M79,80.5L79,80.5c0.8,0,1.5-0.7,1.5-1.5l0,0c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5l0,0
								   C77.5,79.8,78.2,80.5,79,80.5z"
                      />
                    </svg>
                  </div>
                  <div className="row mb-4">
                    <div className="col-xl-4 col-lg-4 col-sm-4 tg-base mb-4">
                      <div className="card text-center">
                        <div className="card-body p-2">
                          <span
                            className="mb-1 p-2 d-block"
                            style={{ color: "#3CDD22" }}
                          >
                            <i
                              className="fa-solid fa-battery-full"
                              style={{ fontSize: "xx-large" }}
                            />
                          </span>
                          <h1 className="mb-0">
                            {activity?.daily?.daily_activities?.battery}
                          </h1>
                          <span
                            className="mb-1 d-block"
                            style={{ fontSize: "14px" }}
                          >
                            Battery Percentage
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-4 tg-base mb-4">
                      <div className="card text-center">
                        <div className="card-body p-2">
                          <span
                            className="mb-1 p-2 d-block"
                            style={{ color: "#0173FF" }}
                          >
                            <i
                              className="fa-solid fa-dog"
                              style={{ fontSize: "xx-large" }}
                            />
                          </span>
                          <h1 className="mb-0">
                            {activity?.daily?.daily_activities?.step_count}
                          </h1>
                          <span
                            className="mb-1 d-block"
                            style={{ fontSize: "14px" }}
                          >
                            Steps
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-4 tg-base mb-4">
                      <div className="card text-center">
                        <div className="card-body p-2">
                          <span
                            className="mb-1 p-2 d-block"
                            style={{ color: "#3CDD22" }}
                          >
                            <i
                              className="fa-solid fa-paw"
                              style={{ fontSize: "xx-large" }}
                            />
                          </span>
                          <h1 className="mb-0">
                            {activity?.daily?.daily_activities?.distance}
                          </h1>
                          <span
                            className="mb-1 d-block"
                            style={{ fontSize: "14px" }}
                          >
                            Distance
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-3 mb-4"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#F5960E",
                      borderRadius: 10,
                    }}
                  >
                    <button
                      className="text-white"
                      style={{
                        border: "none",
                        fontSize: 20,
                        backgroundColor: "#F5960E",
                        fontWeight: 600,
                      }}
                      onClick={() => navigate("/reminder")}
                    >
                      Reminder
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-7 mb-4">
              <div className="card overflow-hidden">
                <div className="card-header border-0 pb-0 flex-wrap">
                  <h1 className=" mb-2 p-4">Location</h1>
                </div>
                <div className="card-body custome-tooltip p-0">
                  {/* <iframe
                    src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15073.72552010207!2d72.834542!3d19.176352!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5dab11b9bf5ff69%3A0xfbb3864e52f7b022!2sPetsSmart!5e0!3m2!1sen!2sin!4v1709190696165!5m2!1sen!2sin&zoom=15&output=embed`}
                    width="100%"
                    height="500px"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe> */}
                  {/* <div ref={mapContainer} className="map-container" /> */}
                  {activity?.daily?.daily_activities && (
                    <Map
                      targetLocation={[
                        activity?.daily?.daily_activities?.longitude,
                        activity?.daily?.daily_activities?.latitude,
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-sm-6 mb-4">
              <div className="card">
                <div className="card-body1">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="icon-box icon-box-lg bg-success-light rounded-circle">
                      <i
                        className="fa-solid fa-location-dot"
                        style={{ color: "#F8B93F" }}
                      />
                    </div>
                    <div className="total-projects ms-3">
                      <h1>Current Location</h1>
                      <h3>Now</h3>
                    </div>
                  </div>
                  <div
                    className="mt-2"
                    style={{ height: 1, backgroundColor: "black" }}
                  />
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="d-flex">
                      <h3 style={{ color: "#F8B93F" }}>Now :- </h3>
                      <h3>
                        {activity?.daily?.daily_activities?.longitude},{" "}
                        {activity?.daily?.daily_activities?.latitude}
                      </h3>
                    </div>
                    {/* <div className="d-flex">
                      <h3 style={{ color: "#F8B93F" }}>Now:-</h3>
                      <h3>Malad, Malwani</h3>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6">
              <div className="card">
                <div className="card-body2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="icon-box icon-box-lg bg-success-light rounded-circle">
                      <i
                        className="fa-solid fa-moon"
                        style={{ color: "#F8B93F" }}
                      />
                    </div>
                    <div className="total-projects ms-3">
                      <h1>Sleep Treacking</h1>
                      <h3>Status</h3>
                    </div>
                  </div>
                  <div
                    className="mt-2"
                    style={{ height: 1, backgroundColor: "black" }}
                  />
                  <div className="d-flex align-items-center gap-5 mt-3">
                    <div className="d-flex">
                      <div
                        style={{
                          width: 19,
                          height: 39,
                          backgroundColor: "#FF3030",
                          marginRight: 8,
                          borderRadius: 10,
                        }}
                      ></div>
                      <div className="">
                        {`${activity?.sleep?.sleep?.sleephour} ${activity?.sleep?.sleep?.sleepmin} ${activity?.sleep?.sleep?.sleepsec}`}
                        <br />
                        {/* Awake */}
                      </div>
                    </div>
                    {/* <div className="d-flex">
                      <div
                        style={{
                          width: 19,
                          height: 39,
                          backgroundColor: "#288BFF",
                          marginRight: 8,
                          borderRadius: 10,
                        }}
                      ></div>
                      <div className="">
                        6 hr. <br />
                        Sleep
                      </div>
                    </div>
                    <div className="d-flex">
                      <div
                        style={{
                          width: 19,
                          height: 39,
                          backgroundColor: "#24218F",
                          marginRight: 8,
                          borderRadius: 10,
                        }}
                      ></div>
                      <div className="">
                        5 hr. <br />
                        Deep Sleep
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
