import { createSlice } from "@reduxjs/toolkit";
import { notificationState } from "./states/notificationState";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationState,
  reducers: {
    storeNotification: (state, action) => {
      console.log(action);
      state.isNotified = false;
      state.notification = action.payload;
    },
    readNotification: (state, action) => {
      console.log(action);
      state.isNotified = true;
    },
  },
});

export const { storeNotification, readNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
