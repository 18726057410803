import { useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    return element.component;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
