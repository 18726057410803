import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "../Plans/plans.css";
import "./editinfo.css";
import dog from "../../dashboard/dash-img-dog.jpeg";

import Loading from "../Loading/Loading";
import { postApi } from "../../services/axiosInterceptors";

function EditInfo() {
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [pet, setPet] = useState({
    pet_name: user?.user_device?.pet_name,
    breed: user?.user_device?.breed,
    age: user?.user_device?.age,
    weight: user?.user_device?.weight,
    color: user?.user_device?.color,
    gender: user?.user_device?.gender,
    user_name: user?.user_device?.user_name,
    address: user?.user_device?.address,
    pet_image: {},
  });

  const [newuser, setNewUser] = useState({
    user_id: user?.user_id,
    full_name: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    gender: "",
  });

  const handlePetChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setPet((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setPet((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUserChange = (e) => {
    let { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePetSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formdata = new FormData();

    formdata.append("user_id", user?.user_id);
    formdata.append("device_id", user?.user_device?.device_id);

    for (let key in pet) {
      formdata.append(key, pet[key]);
    }

    await postApi("update_petsinfo", formdata).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        alert(res.data.message);
        window.location.reload();
      }
    });
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    await postApi("user_details", newuser)
      .then((res) => {
        if (res.status === 200) {
          alert("UserInfo updated!");
        }
      })
      .catch((Err) => console.log(Err));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="content-body edit mt-4 p-4 mb-4 ">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-sm-6 mb-4">
            <div className="card">
              <form onSubmit={handlePetSubmit} className="card-body2">
                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>
                  Pet Info
                </h1>
                <div className="row d-flex gap-lg-5">
                  <div className="col-xl-5 col-md-12 col-sm-6 mt-3">
                    <img
                      className="w-100"
                      src={dog}
                      style={{ borderRadius: "8px" }}
                      alt=""
                    />
                  </div>
                  <div className="d-flex justify-content-between col-xl-6 col-sm-6 col-md-12">
                    <div className="pet-info">
                      <input
                        className="pet-info"
                        type="file"
                        placeholder="pet-info"
                        name="pet_image"
                        // value={pet.pet_image}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">Pet Name</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="pet_name"
                        value={pet.pet_name}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">Breed</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="breed"
                        value={pet.breed}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">Weight</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="weight"
                        value={pet.weight}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">user_name</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="user_name"
                        value={pet.user_name}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">Color</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="color"
                        value={pet.color}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">Address</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="address"
                        value={pet.address}
                        onChange={handlePetChange}
                      />
                      <h3 className="mt-4 mb-4">Age</h3>
                      <input
                        className="pet-info"
                        type="number"
                        name="age"
                        value={pet.age}
                        onChange={handlePetChange}
                      />
                      <div className="d-flex align-items-center mt-4 gap-4">
                        <input
                          type="radio"
                          id="age1"
                          name="gender"
                          value={"Male"}
                          defaultChecked={pet.gender === "Male"}
                          onChange={handlePetChange}
                        />
                        <h3 className="mt-2" htmlFor="age1">
                          Male
                        </h3>
                        <br />
                        <input
                          type="radio"
                          id="age2"
                          name="gender"
                          value={"Female"}
                          defaultChecked={pet.gender === "Female"}
                          onChange={handlePetChange}
                        />
                        <h3 className="mt-2" htmlFor="age2">
                          Female
                        </h3>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="p-3 mb-4 w-25"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#F5960E",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                >
                  <button
                    className="text-white"
                    style={{
                      border: "none",
                      fontSize: 20,
                      backgroundColor: "#F5960E",
                      fontWeight: 600,
                    }}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-sm-6 mb-4">
            <div className="card">
              <div className="card-body2">
                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>My Info</h1>

                <form onSubmit={handleUserSubmit}>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">Full Name</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="full_name"
                        value={newuser.full_name}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">Email</h3>
                      <input
                        className="pet-info"
                        type="email"
                        name="email"
                        value={newuser.email}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">Phone Number</h3>
                      <input
                        className="pet-info"
                        type="phone"
                        name="phone"
                        value={newuser.phone}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">Country</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="country"
                        value={newuser.country}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">State</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="state"
                        value={newuser.state}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">City</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="city"
                        value={newuser.city}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">Pincode</h3>
                      <input
                        className="pet-info"
                        type="number"
                        name="pincode"
                        value={newuser.pincode}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-md-6">
                      <h3 className="mt-4 mb-4">Address</h3>
                      <input
                        className="pet-info"
                        type="text"
                        name="address"
                        value={newuser.address}
                        onChange={handleUserChange}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4 gap-4">
                      <input
                        type="radio"
                        id="age1"
                        value="Male"
                        name="gender"
                        onChange={handleUserChange}
                      />
                      <h3 className="mt-2" htmlFor="age1">
                        Male
                      </h3>
                      <br />
                      <input
                        type="radio"
                        id="age2"
                        value="Female"
                        name="gender"
                        onChange={handleUserChange}
                      />
                      <h3 className="mt-2" htmlFor="age2">
                        Female
                      </h3>
                      <br />
                    </div>
                  </div>
                  <div
                    className="p-3 mb-4 mt-4 w-25"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#F5960E",
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                  >
                    <button
                      className="text-white"
                      style={{
                        border: "none",
                        fontSize: 20,
                        backgroundColor: "#F5960E",
                        fontWeight: 600,
                      }}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditInfo;
