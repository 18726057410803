import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";

import "../Plans/plans.css";
import device1 from "../../dashboard/device1.png";
import "./sos.css";
import { postApi } from "../../services/axiosInterceptors";
import Loading from "../Loading/Loading";

function SOS() {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  // const [users, setUsers] = useState([
  //   {
  //     name: ["Sachin"],
  //   },
  //   {
  //     name: ["Manish"],
  //   },
  // ]);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    let obj = {
      mac_address: user.user_device.mac_address,
    };

    const fetchData = async () => {
      setLoading(true);

      await postApi("sos_call_list", obj)
        .then((res) => {
          if (res.status === 200) {
            setPlans(res.data);
          }
        })
        .catch((err) => console.log(err));
    };

    obj && fetchData().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="content-body2 mt-4 p-4">
      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>SOS Report</h1>
            <div className="d-flex align-items-center cursor-pointer download px-3">
              <i className="fa-solid fa-download"></i>
              <h3 className="mt-2 p-2 text-white">Download Excel</h3>
            </div>
          </div>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={1000}
            centerMode={false}
            containerClass="container-with-dots"
            draggable
            className="mb-4"
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
          >
            {plans?.sos_call_list?.length > 0 &&
              plans?.sos_call_list?.map((plan, index) => (
                //   <div key={index} className="col-xl-3 col-sm-6 mb-4">
                <div key={index} className="card">
                  <div className="card-body2 d-flex justify-content-between">
                    <div className="col-xl-2 col-sm-6 mb-4 d-flex ">
                      <div className="col">
                        <img src={device1} alt="" />
                      </div>
                    </div>
                    <div className="col-xl-8 col-sm-6 mb-4">
                      <div className="d-flex py-2 flex-column">
                        <h3>Date : {plan.date}</h3>
                        <h3>Time : {plan.time}</h3>
                        <h3>From : +91 {user?.user_device?.sim_no}</h3>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              ))}
          </Carousel>
        </div>
        {/* <div className="row">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Users</h1>
            <div className="d-flex align-items-center cursor-pointer download px-3">
              <i className="fa-solid fa-plus mx-1"></i>
              <h3 className="mt-2 p-2 text-white">Add New User</h3>
            </div>
          </div>

         
          {users.map((user, index) => (
            <div key={index} className="col-xl-2 col-sm-6 mb-4">
              <div className="card">
                <div className="card-body2 d-flex justify-content-between">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                      <i className="fa-solid fa-user"></i>
                    </div>
                  </div>
                  <div>
                    {user.name.map((name, index) => (
                      <div key={index} className="d-flex py-2">
                        <h3>{name}</h3>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default SOS;
