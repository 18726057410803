import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import "react-multi-carousel/lib/styles.css";
import "react-phone-number-input/style.css";
import "mapbox-gl/dist/mapbox-gl.css";

import ScrollToTop from "react-scroll-to-top";
import Layout from "./components/Layout/Layout";

import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
// import OtpInput from "./components/Otp/OtpInput";
import { routes } from "./services/routes";
import ProtectedRoute from "./services/ProtectedRoute";
import OTPVerification from "./components/OTPVerification/OTPVerification";
import Successful from "./components/Successful/Successful";
import LinkDevice from "./components/LinkDevice/LinkDevice";
import DeviceConnect from "./components/DeviceConnect/DeviceConnect";
import QrScanner from "./components/QrScanner/QrScanner";

import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase.config";
import { useState } from "react";
import AlertDismissible from "./components/Alert/Alert";

function App() {
  const [notification, setNotification] = useState(null);

  onMessage(messaging, (payload) => {
    console.log("incoming msgssssss", payload);
    if (payload.notification) {
      setNotification(payload.notification);
    }
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <div>
            {notification && (
              <AlertDismissible
                notification={notification}
                onClose={() => setNotification(null)}
              />
            )}
          </div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* <Route path="/verify" element={<OtpInput />} /> */}

            <Route path="/otpverification" element={<OTPVerification />} />
            <Route path="/qrscanner" element={<QrScanner />} />
            <Route path="/successful" element={<Successful />} />
            <Route path="/linkdevice" element={<LinkDevice />} />
            <Route path="/deviceconnect" element={<DeviceConnect />} />

            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<ProtectedRoute element={route} />}
              />
            ))}
          </Routes>

          <ScrollToTop smooth />
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
