import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../Login/login.css";
import { postApi } from "../../services/axiosInterceptors";
function Register() {
  const [register, setRegister] = useState({
    full_name: "",
    email: "",
    phone: "",
    aadhar_card_number: "",
    address: "",
    aadhar_image: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setRegister((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setRegister((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    for (let key in register) {
      formData.append(key, register[key]);
    }

    await postApi("user_register", formData)
      .then((res) => {
        if (res.status === 201) {
          localStorage.setItem("tempUserId", res.data.user.id);
          localStorage.setItem("tempPhone", res.data.user.phone);
          navigate("/verify", {
            state: {
              data: res.data,
              route: "register",
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container-fluid login-background">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-3">
            <h1
              className="text-white mt-4 mb-4"
              style={{ fontWeight: "600", fontSize: "3rem" }}
            >
              Welcome back! Glad to see you, Again!
            </h1>
            <form onSubmit={handleSubmit}>
              <input
                className="pet-info mt-4"
                type="text"
                placeholder="Full Name"
                name="full_name"
                onChange={handleChange}
              />
              <input
                className="pet-info mt-4"
                type="text"
                placeholder="Email"
                name="email"
                onChange={handleChange}
              />
              <input
                className="pet-info mt-4"
                type="text"
                placeholder="Phone No."
                name="phone"
                onChange={handleChange}
              />
              <input
                className="pet-info mt-4"
                type="text"
                placeholder="Aadhar No."
                name="aadhar_card_number"
                onChange={handleChange}
              />
              <input
                className="pet-info mt-4"
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleChange}
              />
              <input
                className="pet-info mt-4"
                type="file"
                placeholder="Upload Addhar Image"
                name="aadhar_image"
                onChange={handleChange}
              />
              <div
                className="p-3 mb-4  mt-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "#F5960E",
                  borderRadius: 10,
                }}
              >
                <button
                  type="submit"
                  className="text-white"
                  style={{
                    border: "none",
                    fontSize: 20,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                  }}
                >
                  Register
                </button>
              </div>
            </form>
            <h3 className="text-white">
              <Link
                to={"/otpverification"}
                style={{ fontWeight: "600", color: "white" }}
              >
                Remember Password ?
              </Link>{" "}
              <Link
                to={"/login"}
                style={{ fontWeight: "600", color: "#f5960e" }}
              >
                Login Now
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
