import Dashboard from "../components/Dashboard/Dashboard";
import Header from "../components/Header/Header";
import Plans from "../components/Plans/Plans";
import Home from "../components/Home/Home";
import Payments from "../components/Payments/Payments";
import Account from "../components/Account/Account";
import SOS from "../components/SOS/SOS";
import EditInfo from "../components/EditInfo/EditInfo";
import Settings from "../components/Settings/Settings";
import Reminder from "../components/Reminder/Reminder";

export const routes = [
  { path: "/", component: <Home /> },
  { path: "/header", component: <Header /> },
  { path: "/plans", component: <Plans /> },
  { path: "/payments", component: <Payments /> },
  { path: "/account", component: <Account /> },
  { path: "/sos", component: <SOS /> },
  { path: "/editinfo", component: <EditInfo /> },
  { path: "/settings", component: <Settings /> },
  { path: "/reminder", component: <Reminder /> },
  { path: "/dashboard", component: <Dashboard /> },
];
