import React, { useState } from "react";
// import '../Login/login.css'
import "../Successful/successful.css";
import Circle from "../../dashboard/circle.png";
import { Link } from "react-router-dom";

function DeviceConnect() {
  const [otp, setOtp] = useState("");
  return (
    <div className="container-fluid successful-background">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-3 col-xxl-3 text-center">
            <h1
              className="text-white mb-4 mt-4 font-bold"
              style={{ fontSize: "2.5rem", fontWeight: "600" }}
            >
              Your New Device Connected Successfully
            </h1>
            <img src={Circle} alt="" className="mb-4" />
            <Link to={"/editinfo"}>
              <div
                className="p-3 mb-4  mt-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "#F5960E",
                  borderRadius: 10,
                }}
              >
                <button
                  className="text-white"
                  style={{
                    border: "none",
                    fontSize: 20,
                    backgroundColor: "#F5960E",
                    fontWeight: 600,
                  }}
                >
                  Device Connected
                </button>
              </div>
            </Link>
            {/* <h3 className='text-white'>Don’t have an account? <Link to={'/register'} style={{ fontWeight: '600', color: "#f5960e" }}>Register Now</Link></h3> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceConnect;
